import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import headerStyles from './css_modules/header.module.scss'
import mobileNav from './css_modules/mobilenav.module.scss'

const Header = () => (
  <header className={headerStyles.header} id="header">
    <div className={headerStyles.mainNavWrap}>
      <div className={headerStyles.container}>
        <nav className={headerStyles.mainNav}>
          <Link to="/" className={headerStyles.navItemLogo}></Link>
          <ul className={headerStyles.navList}>
            <li>
              {/* <Link to="/" className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}>Home</Link> */}
              <Link to="our-services" className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}>Services</Link>
              {/* <Link to="our-events" className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}>Events</Link> */}
              <Link to="our-story" className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}>About</Link>
              <Link to="portfolio" className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}>Portfolio</Link>
              <Link to="blog" className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}>Insights</Link>
              <Link to="contact" className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}>Contact</Link>
            </li>
          </ul>
          <Link to="contact" className={headerStyles.mainButton}>Free consultations</Link>
        </nav>

        <div className={headerStyles.mobileNavWrapper}>
          <div className={mobileNav.menuIco}>
            <span></span>
            <Link to="/" className={headerStyles.navItemLogo + ' ' + mobileNav.navItemLogo}></Link>
            <ul className={mobileNav.menu}>
              {/* <li><Link to="/" >Home</Link></li> */}
              <li><Link to="our-services">Services</Link></li>
              <li><Link to="our-story">About</Link></li>
              <li><Link to="portfolio">Portfolio</Link></li>
              <li><Link to="blog">Insights</Link></li>
              <li><Link to="contact">Contact</Link></li>
            </ul>
          </div>
        </div>
      </div >
    </div >
  </header >
)



// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }
export default Header
