import React from "react"
//import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import "./css_modules/layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header />
      <main>{children}</main>
      <form name="contact" netlify netlify-honeypot="bot-field" hidden>
        <input type="text" name="name" placeholder="" />
        <input type="text" name="phone" />
        <input type="email" name="email" />
        <input type="text" name="company" />
        <input type="text" name="website" />
        <textarea name="message"></textarea>
      </form>
      <Footer />
    </>
  )
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout
