import React from 'react'
import { Link } from "gatsby"
import PropTypes from "prop-types"
import footerStyles from './css_modules/footer.module.scss'

const Footer = () => {
    return (
        <footer className={footerStyles.footer}>
            <div className={footerStyles.container}>
                <nav className={footerStyles.mainNav}>
                    <Link to="/" className={footerStyles.navItemLogo}></Link>
                    <ul className={footerStyles.navList}>
                        <li>
                            {/* <Link to="/" className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem}>Home</Link> */}
                            <Link to="our-services" className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem}>Services</Link>
                            {/* <Link to="our-events" className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem}>Events</Link> */}
                            <Link to="our-story" className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem}>About</Link>
                            <Link to="portfolio" className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem}>Portfolio</Link>
                            <Link to="blog" className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem}>Blog</Link>
                            <Link to="contact" className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem}>Contact</Link>
                            {/* <Link to="career" className={footerStyles.navItem} activeClassName={footerStyles.activeNavItem}>Career</Link> */}
                        </li>
                    </ul>
                </nav>
                <div className={footerStyles.lowerSection}>
                    <div className={footerStyles.socialSection}>
                        <p>Follow us on</p>
                        <div className={footerStyles.socials}>
                            <p>
                                <a href="https://www.linkedin.com/company/18737080/" className={footerStyles.linkedinIco}></a>
                                <a href="https://www.instagram.com/digitalmadnesscompany/" className={footerStyles.instagramIco}></a>
                                <a href="https://www.facebook.com/digitalmadnesscompany/" className={footerStyles.facebookIco}></a>
                                <a href="mailto: hello@digitalmadness.rocks" className={footerStyles.mailIco}></a>
                                <a href="https://www.youtube.com/channel/UCtws81KhN4v8_taBZnq4SVQ" className={footerStyles.ytIco}></a>
                            </p>
                        </div>
                    </div>
                    <div className={footerStyles.copyright}>
                        <p>Copyright © {new Date().getFullYear()} DigitalMadness</p>
                        <p>All Rights Reserved.</p>
                    </div>
                </div>
            </div>
            {` `}
        </footer >
    )
}

export default Footer
